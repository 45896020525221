<template>
  <div class="unit-detail">
    <ul class="tabs">
      <li
        class="tab-item"
        :class="[
          $route.name.includes('Interpretation') ? 'tab-item-select' : '',
        ]"
        @click="showUnitInerpretation"
      >
        单元解读
      </li>
      <li
        class="tab-item"
        :class="[$route.name.includes('essonDesign') ? 'tab-item-select' : '']"
        @click="showLessonDesign"
      >
        单课设计
      </li>
    </ul>
    <div class="root-view">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "unitInterpretation",
    };
  },
  methods: {
    showUnitInerpretation() {
      if (this.$route.meta.review == "true") {
        //审核
        this.$router.push({
          name: "reviewUnitInterpretation",
          query: this.$route.query,
        });
      } else {
        this.$router.push({
          name: "unitInterpretation",
          query: this.$route.query,
        });
      }
    },
    showLessonDesign() {
      if (this.$route.meta.review == "true") {
        //审核
        this.$router.push({
          name: "reviewLessonDesign",
          query: this.$route.query,
        });
      } else {
        this.$router.push({ name: "lessonDesign", query: this.$route.query });
      }
    },
  },
};
</script>

<style lang="less">
.unit-detail {
  position: relative;
  .tabs {
    padding: 0;
    padding-top: 30px;
    position: absolute;
    z-index: 100;
    .tab-item {
      width: 124px;
      height: 80px;
      background: #e3e3e6;
      opacity: 1;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      text-align: center;
      line-height: 80px;
      overflow: hidden;
      margin-top: 60px;
      font-size: 30px;
      padding: 0 24px;
      color: #8e8e93;
      cursor: pointer;
      font-weight: normal;
    }
    .tab-item-select {
      width: 192px;
      height: 80px;
      background: #6e76ff;
      box-shadow: 0px 8px 16px rgba(44, 44, 193, 0.4);
      opacity: 1;
      border-radius: 20px;
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      font-weight: bold;
      padding: 0 36px;
    }
  }
  .root-view {
    margin-left: 124px;
    margin-right: 30px;
    margin-top: 60px;
    background: white;
    border-radius: 30px;
    z-index: 80;
    margin-bottom: 60px;
    min-height: 100vmin;
  }
}
</style>